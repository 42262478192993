import { render, staticRenderFns } from "./Bundle.vue?vue&type=template&id=547e54fa&lang=pug&"
import script from "./Bundle.vue?vue&type=script&lang=ts&"
export * from "./Bundle.vue?vue&type=script&lang=ts&"
import style0 from "./Bundle.vue?vue&type=style&index=0&id=547e54fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports